import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import TeamViewerLogo from "../assets/svg/logos/teamviewer.svg"

const IndexPage = () => (
  <Layout>
    <Seo title="Hilfe" />
    <section className="page">
      <h1 className="text_huge">Hilfe</h1>
      <p>
        TeamViewer einfach herunterladen, doppelklicken und ID mit Passwort an den Supporter weitergeben.
      </p>
      <p>
        <a href="https://get.teamviewer.com/de/yqp9437" className="download-help no-decoration" aria-label="TeamViewer Software herunterladen" target="_blank" rel="noopener noreferrer">
          <TeamViewerLogo className="big-icon" />
        </a>
      </p>
    </section>
  </Layout>
)

export default IndexPage
